@media (prefers-color-scheme: light) {
	.wrapper {
		--box-border-color: #e5e7eb;
		--color: #1f2937;
		--bg-color: #f3f4f6;
		--btn-color: #1f2937;
		--btn-bg-color: #e5e7eb;
		--btn-color-hover: #1f2937;
		--btn-bg-color-hover: #d1d5db;
	}
}
@media (prefers-color-scheme: dark) {
	.wrapper {
		--box-border: #111827;
		--color: #f3f4f6;
		--bg-color: #111827;
		--btn-color: #f3f4f6;
		--btn-bg-color: #1f2937;
		--btn-color-hover: #f3f4f6;
		--btn-bg-color-hover: #334155;
	}
}
.wrapper > :global(.mep-next-iubenda-loading),
.wrapper > :global(.mep-next-iubenda-consent-not-granted) {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	padding: 1rem 2rem;
	width: 100%;
	text-align: center;
	color: var(--color);
	border-radius: 0.375rem;
	background-color: var(--bg-color);
	border: 1px solid var(--box-border-color);
}
.wrapper > :global(.mep-next-iubenda-loading) button,
.wrapper > :global(.mep-next-iubenda-consent-not-granted) button {
	padding: 0.5rem 1rem;
	font-size: 0.9rem;
	color: var(--btn-color);
	border-radius: 0.375rem;
	background-color: var(--btn-bg-color);
}
.wrapper > :global(.mep-next-iubenda-loading) button:hover,
.wrapper > :global(.mep-next-iubenda-consent-not-granted) button:hover {
	color: var(--btn-color-hover);
	background-color: var(--btn-bg-color-hover);
}
